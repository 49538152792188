import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {ButtonPriority, Text} from 'wix-ui-tpa';
import {classes} from './Footer.st.css';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {CartButton} from '../CartButton/CartButton';
import {SecureCheckout} from '../../../../../common/components/SecureCheckout/SecureCheckout';
import {Violations} from '../../../../../common/components/Violations/Violations';
import settingsParams from '../../../settingsParams';
import {useSettings} from '@wix/tpa-settings/react';

export enum FooterDataHooks {
  root = 'Footer.root',
  subtotalContainer = 'Footer.subtotalContainer',
  subtotalLabel = 'Footer.subtotalLabel',
  subtotalValue = 'Footer.subtotalValue',
  subtotalDisclaimer = 'Footer.subtotalDisclaimer',
}

export const Footer = () => {
  const {t} = useTranslation();
  const {cart, shouldRenderEmptyState, shouldUseNewTagsForCart} = useControllerProps().cartStore;
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {get: getSettings} = useSettings();
  if (shouldRenderEmptyState) {
    return false;
  }
  const shouldShowTotalsDisclaimer = getSettings(settingsParams.CART_SUMMARY_DISCLAIMER_VISIBILITY) as boolean;
  const shouldShowSecureCheckout = getSettings(settingsParams.CART_SUMMARY_SECURE_BADGE_VISIBILITY) as boolean;
  const shouldGoToCartButton = getSettings(settingsParams.CART_ACTION_BUTTONS_GO_TO_CART_VISIBILITY) as boolean;
  const shouldShowCheckoutButton = getSettings(settingsParams.CART_ACTION_BUTTONS_CHECKOUT_VISIBILITY) as boolean;

  return (
    <section className={classes.root} data-hook={FooterDataHooks.root}>
      <div className={classes.subtotalRow} aria-live="assertive" data-hook={FooterDataHooks.subtotalContainer}>
        <Text tagName={'h2'} data-hook={FooterDataHooks.subtotalLabel} className={classes.subtotalLabel}>
          {t('sideCart.subtotal.label')}
        </Text>

        <Text data-hook={FooterDataHooks.subtotalValue} className={classes.subtotalValue} data-wix-sub-totals-value>
          {cart.convertedTotals.formattedItemsTotal}
        </Text>
      </div>
      {shouldShowTotalsDisclaimer && (
        <div className={classes.disclaimer}>
          <Text data-hook={FooterDataHooks.subtotalDisclaimer} className={classes.disclaimerText}>
            {t('sideCart.subtotal.disclaimer')}
          </Text>
        </div>
      )}
      <Violations />
      <div className={classes.buttons}>
        {shouldShowCheckoutButton && (
          <CheckoutButton
            priority={ButtonPriority.primary}
            disabled={isCheckoutButtonDisabled}
            text={getSettings(settingsParams.CART_BUTTON_TEXT) as string}
          />
        )}
        {shouldGoToCartButton && <CartButton />}
      </div>
      {shouldShowSecureCheckout && (
        <SecureCheckout text={t('sideCart.secureCheckout.badge')} shouldUseNewTagsForCart={shouldUseNewTagsForCart} />
      )}
    </section>
  );
};
